// extracted by mini-css-extract-plugin
export var home = "index-module--home--1d1ca";
export var header = "index-module--header--3c127";
export var hamburgermenu = "index-module--hamburgermenu--7b66e";
export var inicio = "index-module--inicio--82a78";
export var textwrapper = "index-module--textwrapper--66074";
export var blog = "index-module--blog--19716";
export var div = "index-module--div--64074";
export var tienda = "index-module--tienda--b3d5b";
export var textwrapper2 = "index-module--textwrapper2--d5df9";
export var contacto = "index-module--contacto--224e6";
export var textwrapper3 = "index-module--textwrapper3--44aab";
export var categorias = "index-module--categorias--44344";
export var textwrapper4 = "index-module--textwrapper4--237a5";
export var usermenu = "index-module--usermenu--ee8ea";
export var themeswitcher = "index-module--themeswitcher--da60b";
export var ellipse = "index-module--ellipse--da1b1";
export var vector = "index-module--vector--94443";
export var carticon = "index-module--carticon--f5bad";
export var usericon = "index-module--usericon--51e4e";
export var main = "index-module--main--1ba09";
export var overlap = "index-module--overlap--f61b8";
export var contenedorservicios = "index-module--contenedorservicios--73d7c";
export var contenedorservicios2 = "index-module--contenedorservicios2--bd645";
export var packsdeservicios = "index-module--packsdeservicios--dc77e";
export var brainillustration = "index-module--brainillustration--e419d";
export var textwrapper5 = "index-module--textwrapper5--d29bf";
export var packsdeservicios2 = "index-module--packsdeservicios2--115fa";
export var img = "index-module--img--47de6";
export var textwrapper6 = "index-module--textwrapper6--830b4";
export var consultoriasenia = "index-module--consultoriasenia--19fd0";
export var brainillustration2 = "index-module--brainillustration2--9d16c";
export var textwrapper7 = "index-module--textwrapper7--273e5";
export var consultoriasenia2 = "index-module--consultoriasenia2--50ade";
export var brainillustration3 = "index-module--brainillustration3--9bf34";
export var textwrapper8 = "index-module--textwrapper8--b7297";
export var contenedortitulo = "index-module--contenedortitulo--4ecac";
export var line = "index-module--line--9de92";
export var textwrapper9 = "index-module--textwrapper9--c8c0a";
export var brainillustration4 = "index-module--brainillustration4--c1dc2";
export var contenedorservicios3 = "index-module--contenedorservicios3--16b14";
export var promptsparacrear = "index-module--promptsparacrear--49b12";
export var starssvgrepocom = "index-module--starssvgrepocom--1a882";
export var textwrapper10 = "index-module--textwrapper10--5fecc";
export var superprompts = "index-module--superprompts--03a8c";
export var starssvgrepocom2 = "index-module--starssvgrepocom2--54c4f";
export var textwrapper11 = "index-module--textwrapper11--0560d";
export var promptsdepago = "index-module--promptsdepago--3312d";
export var starssvgrepocom3 = "index-module--starssvgrepocom3--2a190";
export var textwrapper12 = "index-module--textwrapper12--73e27";
export var promptsgratis = "index-module--promptsgratis--ac378";
export var starssvgrepocom4 = "index-module--starssvgrepocom4--5aeec";
export var textwrapper13 = "index-module--textwrapper13--1db6d";
export var contenedortitulo2 = "index-module--contenedortitulo2--adf94";
export var overlapgroup = "index-module--overlapgroup--307d4";
export var textwrapper14 = "index-module--textwrapper14--f6b8e";
export var starssvgrepocom5 = "index-module--starssvgrepocom5--5d0b8";
export var line2 = "index-module--line2--04a99";
export var contenedorservicios4 = "index-module--contenedorservicios4--01638";
export var capacitaciones = "index-module--capacitaciones--f0dd2";
export var graduatesvgrepocom = "index-module--graduatesvgrepocom--21c5e";
export var textwrapper15 = "index-module--textwrapper15--0b36a";
export var tutoriales = "index-module--tutoriales--56eb0";
export var graduatesvgrepocom2 = "index-module--graduatesvgrepocom2--ba9e1";
export var textwrapper16 = "index-module--textwrapper16--96e23";
export var cursosdepago = "index-module--cursosdepago--355af";
export var graduatesvgrepocom3 = "index-module--graduatesvgrepocom3--89734";
export var textwrapper17 = "index-module--textwrapper17--ccf79";
export var cursosgratuitos = "index-module--cursosgratuitos--fd015";
export var graduatesvgrepocom4 = "index-module--graduatesvgrepocom4--88a85";
export var textwrapper18 = "index-module--textwrapper18--37811";
export var contenedortitulo3 = "index-module--contenedortitulo3--376a7";
export var textwrapper19 = "index-module--textwrapper19--8f84c";
export var graduatesvgrepocom5 = "index-module--graduatesvgrepocom5--64de8";
export var line3 = "index-module--line3--9c2e2";
export var overlapwrapper = "index-module--overlapwrapper--7c7ed";
export var overlap2 = "index-module--overlap2--4ba14";
export var herramientas = "index-module--herramientas--91b9b";
export var vector2 = "index-module--vector2--f4f91";
export var textwrapper20 = "index-module--textwrapper20--276ef";
export var generacionde = "index-module--generacionde--a0126";
export var vector3 = "index-module--vector3--7c64f";
export var textwrapper21 = "index-module--textwrapper21--f34f3";
export var overlapgroupwrapper = "index-module--overlapgroupwrapper--d7338";
export var overlapgroup2 = "index-module--overlapgroup2--9f0db";
export var textwrapper22 = "index-module--textwrapper22--22002";
export var imagesvgrepocom = "index-module--imagesvgrepocom--ac599";
export var line4 = "index-module--line4--cb45c";
export var contenedorchat = "index-module--contenedorchat--46876";
export var contenedorbarrade = "index-module--contenedorbarrade--119dc";
export var lupabuscadoria = "index-module--lupabuscadoria--9ac1b";
export var barradebusqueda = "index-module--barradebusqueda--b4e41";
export var searchexample = "index-module--searchexample--f7c8c";
export var flechaenviar = "index-module--flechaenviar--c4780";